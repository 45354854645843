import * as React from "react";
import {saveContact} from "../../utils/api";
import "./RegistrationForm.scss";
import PropTypes from 'prop-types';
import {
    Container, Col, Form,
    FormGroup, Label, Input,
    Button, FormFeedback, Spinner
  } from 'reactstrap';

class RegistrationForm extends React.Component {
    static propTypes = {
        checkData: PropTypes.object,
        callback: PropTypes.func,
        token: PropTypes.string
      }

    constructor(props) {
        super(props);
       
        this.state = {           
          form: {
            email: this.props.checkData.email,
            firstName: '',
            lastName: '',
            company: ''
          },
          submitting: false,         
          validate: { email:'has-success' , firstName: '', lastName: ''}
        };
        
        this.validated = this.validated.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

    submitForm(e) {
        e.preventDefault();
     
        const { form, validate } = this.state;
        if (form.email === '' || form.firstName ==='' || form.lastName ===''){
          this.validateInput('email', {target: {value: form.email}});
          this.validateInput('firstName', {target: {value: form.firstName}});
          this.validateInput('lastName', {target: {value: form.lastName}});
        }
        else
        {
          const token = this.props.token;
          this.setState({ submitting: true }, function() {
            saveContact(form, token)
              .then(response => {        
                if (response.status === 200) {
                  return response.json()
                } else {
                  return {error: true, exists: false};
                }
              })
              .then(data => {
                if (data.error) {
                  validate['email'] = 'has-danger';
                }

                this.setState({ validate, submitting: false });
                this.props.callback(data);
              })
              .catch( error => {
                console.log(error);
                this.setState({ checkData: null, checked: true });
            });
          });
        }
                   
      }
    
      validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
          if (emailRex.test(e.target.value)) {
            validate.email = 'has-success'
          } else {
            validate.email = 'has-danger'
          }
          this.setState({ validate })
      }
    
      validateInput(field, e) {    
        const { validate } = this.state
          if (e.target.value.trim() !== '') {
            validate[field] = 'has-success'
          } else {
            validate[field] = 'has-danger'
          }
          this.setState({ validate })
      }

      validated() {
        const { validate } = this.state;
        let validated = true;
        Object.entries(validate).forEach(([key, value]) => {
            if (value !== "has-success"){
                validated = false;
                //break;
            }
            console.log(`key= ${key} value = ${value}`)
        });
        return validated;
      }
    
      handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        const { form } = this.state;
        form[name] = value;
        await this.setState({
          form
        });
      }


    render() {
        const { form, submitting } = this.state;
        //const checkData = this.props.checkData;
        const isValid = this.validated();
        return (
          <Container >
            <div className="form-header">
              <h2>User Registration</h2>
              <p>Please, fill out the form to register as the user of Investment Café</p>    
            </div>
            <Form className="form" onSubmit={ (e) => this.submitForm(e) }>        
              <Col>
                <FormGroup>
                  <Label for="firstName">First Name<span className="required-mark">*</span></Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"                    
                    value={ form.firstName }
                    placeholder="Enter first name"
                    valid={ this.state.validate.firstName === 'has-success' }
                    invalid={ this.state.validate.firstName === 'has-danger' }
                    onChange={ (e) => {                      
                      this.validateInput('firstName', e);                          
                      this.handleChange(e);
                      }
                    }
                    
                  />
                  <FormFeedback invalid="true">
                    First name is required.
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="lastName">Last Name<span className="required-mark">*</span></Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={ form.lastName }
                    placeholder="Enter last name"
                    valid={ this.state.validate.lastName === 'has-success' }
                    invalid={ this.state.validate.lastName === 'has-danger' }
                    onChange={ (e) => {                      
                      this.validateInput('lastName', e);                    
                      this.handleChange(e);
                      }
                    }
                  />
                  <FormFeedback invalid="true">
                    Last name is required.
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="company">Company</Label>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    value={ form.company }
                    placeholder="Enter company"
                    onChange={ this.handleChange }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Email<span className="required-mark">*</span></Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="myemail@email.com"
                    value={ form.email }
                    readOnly={true}
                    valid={ this.state.validate.email === 'has-success' }
                    invalid={ this.state.validate.email === 'has-danger' }
                    onChange={ (e) => {                      
                          this.validateInput('email', e);
                          this.validateEmail(e);                    
                          this.handleChange(e);
                          }
                        }
                  />
                  <FormFeedback invalid="true">
                  Email is not properly formatted
                  </FormFeedback>                  
                </FormGroup>
              </Col>
              <Col>{submitting ? 
                (<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />)
                :
                (<Button className="submit" color="primary" disabled={!isValid}>Submit</Button>)}</Col>          
            </Form>
          </Container>);
    }
}

export default RegistrationForm;