import React, { Component } from "react";
import PropTypes from 'prop-types';
import IcxSpinner from "../Spinner/Spinner";
import OperationError from '../OperationError/OperationError';
import {checkEmail} from "../../utils/api";
import RegistrationForm from "../RegistrationForm/RegistrationForm";
import RegisterSuccess from "../RegisterSuccess/RegisterSuccess";


class CheckEmail extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired
  }
  
  constructor(props) {
    super(props);
   
    this.state = { 
      checkData: null, 
      checked: false, 
      existing: false   
    };
    this.getForm = this.getForm.bind(this);
    this.handleState = this.handleState.bind(this);
  }

  componentDidMount() {  
    const token = this.props.token;    
    checkEmail(token)
      .then(response => response.json())
      .then(data => {
        this.setState({ checkData: data, checked: true, existing: data.exists });
      })
      .catch( error => {
        console.log(error);
        this.setState({ checkData: null, checked: true });        
    });
  }

  handleState(checkData){
    this.setState({ checkData, checked: true });
  }
  
  

  getForm(){    
    const {checkData } = this.state;
    
    return (<RegistrationForm checkData={checkData} callback={this.handleState} token={this.props.token}/>);
  }

  render() {    
    const {checked, checkData, existing } = this.state;
    let renderLayout ;
    if (checked) {
      if(checkData == null){
        renderLayout = <OperationError checkData={checkData} />;
      }else if(checkData.exists === false){
        renderLayout = this.getForm();
      } else if(!checkData.error && checkData.link && existing){
        window.location.href= checkData.link;
        renderLayout = <IcxSpinner loadingMessage={"Redirecting...."} />;
      } else if(!checkData.error && checkData.link){        
        renderLayout = <RegisterSuccess checkData={checkData} />;
      }      
    } else {
      renderLayout = <IcxSpinner loadingMessage={"Checking User Identity...."} />;
    }
    return  <div>{renderLayout}</div>;
  }
}

export default CheckEmail;