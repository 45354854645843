import * as React from "react";
import "./RegisterSuccess.scss";
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class RegisterSuccess extends React.Component {
    static propTypes = {
        checkData: PropTypes.object       
      }
render(){
    const checkData = this.props.checkData;
    return <div className="reg-success">
            <div ><span className="solstice-icon- solstice-icon-success"></span></div>
            <h3>Welcome to Investment Café, registration has been completed successfully!</h3>
            <p>Login credentials have been emailed to your email account.<br />
            Please check your junk folder in case the email does not arrive.
            Alternatively, you can select the “Forgot Password” link on the login page to trigger a temporary password.
            If you still need assistance, contact the support team at 
             &nbsp;<a href="mailto:icsupport@blackrock.com">icsupport@blackrock.com</a>.</p>
            <div><Button color="primary" onClick={() => {window.location.href= checkData.link;}}>Go to login</Button></div>
            </div>
    };
}

export default RegisterSuccess;