
  
  const config = {rootApiUrl: "/", mockup: false,  appInsightsInstrumentationKey: "", signalRHubUrl: ""};
  
  (() => {
    // First, use values from .env files
    config.rootApiUrl = process.env.REACT_APP_ROOT_API || "/";
    config.mockup = process.env.REACT_APP_MOCKUP === "true";
    config.appInsightsInstrumentationKey = process.env.REACT_APP_INSIGHT_INSTRUMENTATION_KEY || "";
    config.signalRHubUrl = process.env.REACT_APP_SIGNALR_HUB_URL || "";
  
    // Then, override by appConfig.js (public folder)
    const CONFIG_KEY = "appConfig";
    if ((window)[CONFIG_KEY] !== undefined) {
      const appConfig = (window)[CONFIG_KEY];
      config.rootApiUrl = appConfig.REACT_APP_ROOT_API;
      config.mockup = appConfig.REACT_APP_MOCKUP === "true";
      config.appInsightsInstrumentationKey = appConfig.REACT_APP_INSIGHT_INSTRUMENTATION_KEY;
      config.signalRHubUrl = appConfig.REACT_APP_SIGNALR_HUB_URL;
      }
  })();
  
  export default config;
  