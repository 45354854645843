import * as React from "react";
import { Button } from 'reactstrap';
import "./OperationError.scss";

class OperationError extends React.Component {
    render() {
        return (
            <div className="reg-error">
            <div ><span className="solstice-icon-error"></span></div>
            <h3>Error Occured</h3>
            <p>Due to a technical issue, your operation couldn’t be completed. Try again later, or contact your administrator for help.</p>
            <div><Button color="primary" onClick={() => {window.close();}}>Close</Button></div>
            </div>);
    }
}

export default OperationError;