import  config from "./config";

export const saveContact = (form, token) => {
    const url = config.rootApiUrl;
    return fetch(`${url}/api/identity`, {
        method: 'post', 
        headers: new Headers({
            'X-ICIM-Token': token, 
            'Content-Type': 'application/json'
        }), 
        body: JSON.stringify(form)
    });    
}

export const checkEmail = (token) => {
    const url = config.rootApiUrl;
    return fetch(`${url}/api/identity/check`, {
        method: 'post', 
        headers: new Headers({
            'X-ICIM-Token': token, 
            'Content-Type': 'application/json'
        })
    });    
}