import React from 'react';
import logo from './efront-logo.png';
import './App.scss';
import CheckEmail from './components/CheckEmail/CheckEmail';

function App() {
  console.log(window.location.pathname);
  const path = window.location.pathname.split('/');
  const token = path[path.length-1];
  return (
    <div className="app">
       <header className="app-header" >
        <span className="logo"></span><img src={logo} className="app-logo" alt="logo" />
       </header>
      <div className="app-main app-center">
      <CheckEmail token={token} />
      </div>
    </div>
  );
}

export default App;
