import * as React from "react";

import "./Spinner.scss";

class Spinner extends React.Component {
    render() {
        return (
            <div className="app-loading loader" >
                <div className="loading-indicator spinner" />
                {this.props.loadingMessage && <div>{this.props.loadingMessage}</div>}
            </div>);
    }
}

export default Spinner;